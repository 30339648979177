<template>
  <BlokkliProvider
    v-slot="{ entity, isEditing }"
    v-bind="blokkli"
    :entity="props"
  >
    <div
      v-if="icons?.length"
      class="container flex flex-row gap-10 pt-20 md:pt-30 lg:pt-40 xl:pt-50"
    >
      <MediaIcon
        v-for="(icon, i) in icons"
        :key="`material_icon_${i}`"
        v-bind="icon"
        :forced-width="80"
        :forced-height="80"
      />
    </div>
    <ContentHeader
      :title="entity?.title || title"
      :lead="entity?.lead || lead"
      :media="entity?.image || image"
      :easy-to-read-url="easyToReadUrl?.path"
    />
    <BlokkliField v-slot="{ items }" :list="paragraphs" name="field_paragraphs">
      <TableOfContents :paragraph-items="items" :is-editing="isEditing" />
    </BlokkliField>
  </BlokkliProvider>

  <BoxSection v-if="organizations?.length || contact">
    <BoxSectionItemOrganizations :organizations="organizations" />
    <BoxSectionItemContact :contact="contact" />
  </BoxSection>
</template>

<script lang="ts" setup>
import type { NodeGarbageMaterialFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  title?: string
  lead?: string
  icons?: NodeGarbageMaterialFragment['icons']
  easyToReadUrl?: NodeGarbageMaterialFragment['easyToReadUrl']
  paragraphs?: NodeGarbageMaterialFragment['paragraphs']
  canEdit?: NodeGarbageMaterialFragment['canEdit']
  image?: NodeGarbageMaterialFragment['image']
  organizations?: NodeGarbageMaterialFragment['organizations']
  contact?: NodeGarbageMaterialFragment['contact']
}>()

const blokkli = buildBlokkliProps(props, 'garbage_material')
</script>
